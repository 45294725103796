import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getUserAccount } from "../configuration/login/loginSlice";
import { prepareHeaders } from "../configuration/tokenHandling/prepareHeaders";
import { checkAccountFlag } from "../utils/account";
import { AccountConfigFlags } from "./enum";
import { RootState } from "../configuration/setup/store";

export const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
	const state = api.getState() as RootState;
	const accountId = getUserAccount(state);
	const baseUrl = checkAccountFlag(accountId!, AccountConfigFlags.VolksUptime)
		? import.meta.env.VITE_API_DEV_BASE_URL
		: import.meta.env.VITE_API_BASE_URL;

	const baseQuery = fetchBaseQuery({
		baseUrl,
		prepareHeaders: (headers) => prepareHeaders(headers),
	});

	return baseQuery(args, api, extraOptions);
};
