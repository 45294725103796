import AssetTree from "@rio-cloud/rio-uikit/AssetTree";
import ErrorState from "@rio-cloud/rio-uikit/ErrorState";
import Spinner from "@rio-cloud/rio-uikit/Spinner";
import Tree, { SelectionChangeResponse } from "@rio-cloud/rio-uikit/Tree";
import TreeCategory from "@rio-cloud/rio-uikit/TreeCategory";
import isEmpty from "lodash/fp/isEmpty";
import isEqual from "lodash/fp/isEqual";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../configuration/setup/hooks";
import { useGetAssetsQuery, useGetTagsQuery } from "../../services/truckApi";
import {
	addAssetsSelected,
	addTagsSelected,
	getAppData,
	setAllTrucksWithoutTag,
	setOverallAssetsSelected,
	setTrucksGlobal,
	setTrucksTagsGlobal,
} from "../../stores/app/appSlice";

const CATEGORY_TRUCKS = "trucks";
const defaultExpandedTruckTags: string[] = [];

export const getEmptyTagTrucks = (trucks: any) =>
	trucks?.reduce((acc: any, curr: any) => {
		return curr.groupIds[0] === "empty" && curr.groupIds.length === 1 ? [...acc, curr.id] : acc;
	}, []);

export const justHasEmptysTag = (assetsList: any, groups: any, emptyTagTrucks: any) =>
	isEqual(assetsList, emptyTagTrucks) && isEqual(groups, ["empty"]);

export const sanitizeAssetsList = (items: any) => {
	const assets = items.map((item: any) => {
		const { items } = item._embedded.tags;
		const groups = items.map((groups: any) => groups.id);
		const groupIds = isEmpty(groups) ? ["empty"] : groups;

		return { id: item.id, name: item.name, type: item.type, groupIds };
	});
	return assets;
};

export function filterTagsByAssets(assets: any, tags: any) {
	const assetTagIds = assets.flatMap((asset: { _embedded: { tags: { items: any[] } } }) =>
		asset._embedded.tags.items.map((tag: { id: any }) => tag.id),
	);
	const relatedTags = tags.filter((tag: { id: any }) => assetTagIds.includes(tag.id));
	return relatedTags;
}

export const createAssetsList = ({ groups, items, trucks }: any) =>
	groups.find((item: any) => item === "empty")
		? [
				...new Set([
					...items,
					...trucks
						.reduce((acc: any, curr: any) => {
							const assetId = curr.groupIds.find((group: any) => group === "empty") && curr.id;
							return acc.concat(assetId);
						}, [])
						.filter((item: any) => item !== undefined),
				]),
			]
		: items;

interface Tag {
	id: string;
	name: string;
	position?: string;
}

export const sanitizeTagsList = (items: any[], assetsWithoutTag: string[], intl: any) => {
	const tags: Tag[] = items.map((item) => ({ id: item.id, name: item.name }));
	if (assetsWithoutTag.length > 0 && tags.length > 0) {
		tags.push({
			id: "empty",
			position: "last",
			name: intl.formatMessage({
				id: "general.app.sidebar.unassigned",
			}),
		});
	}

	return tags;
};

const ApplicationSideBar = () => {
	const intl = useIntl();
	const dispatch = useAppDispatch();
	const [openAppTree, setOpenAppTree] = useState(true);
	const [expandedTruckTags, setExpandedTruckTags] = useState<string[] | undefined>();
	const [currentCategoryId, setCurrentCategoryId] = useState(CATEGORY_TRUCKS);
	const { data: tags, error: errorTags, isLoading: loadingTags } = useGetTagsQuery();
	const { data: assets, error: errorAssets, isLoading: loadingAssets } = useGetAssetsQuery();
	const { assets: assetsGlobal, tags: tagsGlobal, screenType, allAssetsWithoutTag, allTags, allAssets } = useAppSelector(getAppData);

	useEffect(() => {
		async function initialData() {
			if (!loadingAssets && !loadingTags && tags !== undefined && !isEmpty(assets)) {
				const sanitizedAssets = sanitizeAssetsList(assets);
				const assetsWithoutTag = sanitizedAssets.filter((asset: { groupIds: string | string[] }) => asset.groupIds.includes("empty"));
				const assetIdsWithoutTag = assetsWithoutTag.map((asset: { id: string }) => asset.id);
				const tagsWithAssetsIncluded = filterTagsByAssets(assets, tags);
				dispatch(setAllTrucksWithoutTag(assetIdsWithoutTag));
				dispatch(setTrucksTagsGlobal(sanitizeTagsList(tagsWithAssetsIncluded, assetIdsWithoutTag, intl)));
				dispatch(setTrucksGlobal(sanitizedAssets));
			}

			setExpandedTruckTags(defaultExpandedTruckTags);
		}
		initialData();
	}, [assets, errorAssets, errorTags, intl, tags]);

	const handleSelectTruck = ({ items, groups }: SelectionChangeResponse) => {
		const idsWithGroupIds = allAssets
			.filter((item) => item.groupIds && item.groupIds.some((groupId) => groups.includes(groupId)))
			.map((item) => item.id);

		const assetsMergedFromTags = Array.from(new Set([...idsWithGroupIds, ...items]));

		dispatch(setOverallAssetsSelected(assetsMergedFromTags));
		dispatch(addTagsSelected(groups));

		if (groups.includes("empty")) {
			dispatch(addAssetsSelected([...items, ...allAssetsWithoutTag]));
		} else {
			dispatch(addAssetsSelected(items));
		}
	};

	const handleNoItemsSelected = (groups: string[], items: string[]) => {
		if (groups.length > 0 && groups.length === allTags.length) {
			const uniqueItems = Array.from(new Set(allAssetsWithoutTag));
			dispatch(addAssetsSelected(uniqueItems));
		} else if (groups.includes("empty")) {
			dispatch(addAssetsSelected([...items, ...allAssetsWithoutTag]));
		} else if (groups.length === 0 && items.length === 0) {
			dispatch(addAssetsSelected(items));
			alert("n");
		} else if (groups.length > 0) {
			const filteredAssets = allAssets.filter((asset) => !allAssetsWithoutTag.includes(asset.id));
			dispatch(addAssetsSelected(filteredAssets));
		} else {
			alert("a");
			dispatch(addAssetsSelected([]));
		}
	};

	const handleExpandTruckGroups = (expandedTruckGroups: any) => {
		setExpandedTruckTags(expandedTruckGroups);
	};

	const handleChangeCategories = (currentCategoryId: any) => {
		setCurrentCategoryId(currentCategoryId);
	};

	return (
		<AssetTree
			onToggleTree={() => setOpenAppTree(!openAppTree)}
			onCategoryChange={handleChangeCategories}
			currentCategoryId={currentCategoryId}
			isOpen={openAppTree}
			maxWidth={450}
			fly={screenType.MOBILE_SCREEN || screenType.TABLET_SCREEN}
			minWidth={300}
		>
			<TreeCategory
				key={CATEGORY_TRUCKS}
				id={CATEGORY_TRUCKS}
				label={"Trucks"}
				icon={"rioglyph-truck"}
				hasSelection={!isEmpty(assetsGlobal)}
			>
				{loadingAssets && loadingTags && <Spinner />}
				{allTags && allAssets ? (
					<Tree
						items={allAssets}
						groups={allTags}
						hasMultiselect={true}
						selectedItems={assetsGlobal}
						expandedGroups={expandedTruckTags}
						onExpandGroupsChange={handleExpandTruckGroups}
						selectedGroups={tagsGlobal}
						onSelectionChange={handleSelectTruck}
						searchPlaceholder={intl.formatMessage({
							id: "general.app.sidebar.search",
						})}
					/>
				) : (
					!loadingAssets &&
					!loadingTags && (
						<div className="margin-y-auto">
							<ErrorState outerClassName="border-none" condensed headline="Something went wrong" />
						</div>
					)
				)}
			</TreeCategory>
		</AssetTree>
	);
};

export default ApplicationSideBar;
