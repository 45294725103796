import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configuration/setup/store";
import { scheduleApi } from "../../services/scheduleApi";
import { ScheduleData } from "./recurrencePageTypes";

interface RecurrencePageState {
	reloadPage: boolean;
	dialogRecurrenceState: boolean;
	tableRecurrenceData: {
		isLoading: boolean;
		data: any;
	};
	singleRecurrenceData: {
		isLoading: boolean;
		data: ScheduleData | undefined;
	};
}

const initialState: RecurrencePageState = {
	tableRecurrenceData: {
		isLoading: false,
		data: [],
	},
	singleRecurrenceData: {
		isLoading: false,
		data: undefined,
	},
	reloadPage: false,
	dialogRecurrenceState: false,
};

const RecurrencePageSlice = createSlice({
	name: "recurrencePage",
	initialState,
	reducers: {
		triggerReloadPage: (state) => {
			state.reloadPage = !state.reloadPage;
		},
		setTableRecurrenceData: (state, action: PayloadAction<any>) => {
			state.tableRecurrenceData.data = action.payload;
		},
		setTableRecurrenceDataLoading: (state, action: PayloadAction<boolean>) => {
			state.tableRecurrenceData.isLoading = action.payload;
		},
		setSingleRecurrenceData: (state, action: PayloadAction<any>) => {
			state.singleRecurrenceData.data = action.payload;
		},
		openDialogRecurrence: (state) => {
			state.dialogRecurrenceState = true;
		},
		closeDialogRecurrence: (state) => {
			state.dialogRecurrenceState = false;
			state.singleRecurrenceData.data = undefined;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(scheduleApi.endpoints.getSingleRecurrenceData.matchFulfilled, (state, { payload }) => {
			state.singleRecurrenceData.isLoading = false;
			state.singleRecurrenceData.data = payload;
		});
	},
});

export const {
	setTableRecurrenceData,
	triggerReloadPage,
	setSingleRecurrenceData,
	openDialogRecurrence,
	closeDialogRecurrence,
	setTableRecurrenceDataLoading,
} = RecurrencePageSlice.actions;
export const getDialogState = (state: RootState) => state.pageRecurrence.dialogRecurrenceState;
export const getRecurrencePageData = (state: RootState) => state.pageRecurrence;
export default RecurrencePageSlice.reducer;
