import { createHashRouter, createRoutesFromElements, Route } from "react-router-dom";
import { ErrorBoundary } from "../components/error/ErrorBoundary";
import AppLayout from "../layout/AppLayout";
import { suspendPageLoad } from "./suspendPageLoad";

const Dashboard = suspendPageLoad(() => import("../pages/dashboard/DashboardPage"));
const Alerts = suspendPageLoad(() => import("../pages/alerts/AlertsPage"));
const Notifications = suspendPageLoad(() => import("../pages/notifications/NotificationsPage"));
const Recurrence = suspendPageLoad(() => import("../pages/recurrence/RecurrencePage"));
const Downtime = suspendPageLoad(() => import("../pages/downtime/DowntimePage"));

export const DEFAULT_ROUTE = "/dashboard";
export const ALERTS_ROUTE = "/alerts";
export const NOTIFICATIONS_ROUTE = "/notifications";
export const RECURRENCE_ROUTE = "/recurrence";
export const DOWNTIME_ROUTE = "/downtime";

export const routes = [DEFAULT_ROUTE, ALERTS_ROUTE, NOTIFICATIONS_ROUTE, RECURRENCE_ROUTE, DOWNTIME_ROUTE];

export const router = createHashRouter(
	createRoutesFromElements(
		<Route path="/" element={<AppLayout />}>
			<Route path={DEFAULT_ROUTE} errorElement={<ErrorBoundary />} element={<Dashboard />} />
			<Route path={ALERTS_ROUTE} errorElement={<ErrorBoundary />} element={<Alerts />} />
			<Route path={NOTIFICATIONS_ROUTE} errorElement={<ErrorBoundary />} element={<Notifications />} />
			<Route path={RECURRENCE_ROUTE} errorElement={<ErrorBoundary />} element={<Recurrence />} />
			<Route path={DOWNTIME_ROUTE} errorElement={<ErrorBoundary />} element={<Downtime />} />
		</Route>,
	),
);
