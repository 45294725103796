import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../constants/services";

export const digitalScheduleApi = createApi({
	reducerPath: "digitalScheduleApi",
	baseQuery: dynamicBaseQuery,
	endpoints: (builder) => ({
		getClientSchedule: builder.query({
			query: (clientId) => `digitalSchedule/client/${clientId}`,
		}),
		getAllDigitalSchedule: builder.query({
			query: () => ({
				url: `digitalSchedule`,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		getDealers: builder.query<any, void>({
			query: () => `digitalSchedule/dealers`,
		}),
		getServiceByDn: builder.query({
			query: (dn) => `digitalSchedule/serviceDn/${dn}`,
		}),
		createSchedule: builder.mutation({
			query: (requestPayload) => ({
				url: `digitalSchedule`,
				method: "POST",
				body: requestPayload,
			}),
		}),
	}),
});

export const {
	useGetClientScheduleQuery,
	useCreateScheduleMutation,
	useLazyGetAllDigitalScheduleQuery,
	useLazyGetDealersQuery,
	useLazyGetServiceByDnQuery,
} = digitalScheduleApi;
