import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../constants/services";
import { ClientProps } from "../pages/notifications/DigitalSchedule/ClientSchedule";

export const vehicleStatusApi = createApi({
	reducerPath: "vehicleStatusApi",
	baseQuery: dynamicBaseQuery,
	endpoints: (builder) => ({
		getOperativeCards: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "vehicleStatus/operativeCard",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		getFaultCodesCard: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "vehicleStatus/faultCodesCard",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		getMaintenanceCard: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "vehicleStatus/maintenanceCard",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data.scores;
				},
			}),
		}),
		getVehicleStatus: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: "vehicleStatus",
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
		getVehicleDiagnostic: builder.query<any, any>({
			query: (id) => ({
				url: `vehicleStatus/diagnosticData?assetId=${id}`,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
		}),
	}),
});

export const {
	useGetMaintenanceCardMutation,
	useGetVehicleStatusMutation,
	useGetFaultCodesCardMutation,
	useGetOperativeCardsMutation,
	useGetVehicleDiagnosticQuery,
} = vehicleStatusApi;
