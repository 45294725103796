import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../constants/services";

export const dtcApi = createApi({
	reducerPath: "dtcApi",
	baseQuery: dynamicBaseQuery,
	tagTypes: ["Notifications"],
	endpoints: (builder) => ({
		getNotifications: builder.query<any, string>({
			query: (userId: string) => ({
				url: `/dtc/notification?userId=${userId}`,
			}),
			providesTags: ["Notifications"],
		}),
		createDtcNotifications: builder.mutation<any, any>({
			query: (requestPayload) => ({
				url: `/dtc/notification`,
				method: "POST",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
			invalidatesTags: ["Notifications"],
		}),
		deleteSingleNotificationById: builder.mutation<any, { requestPayload?: any; singleId: string }>({
			query: ({ requestPayload, singleId }) => ({
				url: `/dtc/notification/${singleId}`,
				method: "DELETE",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
			invalidatesTags: ["Notifications"],
		}),
		updateSingleNotificationById: builder.mutation<any, { requestPayload?: any; singleId: string }>({
			query: ({ requestPayload, singleId }) => ({
				url: `/dtc/notification/${singleId}`,
				method: "PUT",
				body: requestPayload,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
			invalidatesTags: ["Notifications"],
		}),
	}),
});

export const {
	useLazyGetNotificationsQuery,
	useCreateDtcNotificationsMutation,
	useUpdateSingleNotificationByIdMutation,
	useDeleteSingleNotificationByIdMutation,
} = dtcApi;
