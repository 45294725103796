import { matchRoutes, useLocation } from "react-router-dom";

const useCurrentPath = () => {
	const location = useLocation();

	const getCurrentPath = () => {
		const routes = [{ path: "dashboard" }, { path: "alerts" }, { path: "notifications" }, { path: "recurrence" }, { path: "downtime" }];
		const match = matchRoutes(routes, location);

		if (!match) {
			return null;
		}

		const [{ route }] = match;

		return route.path;
	};

	return getCurrentPath();
};

export default useCurrentPath;
