import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../constants/services";

export const scheduleApi = createApi({
	reducerPath: "scheduleApi",
	baseQuery: dynamicBaseQuery,
	tagTypes: ["Schedules"],
	endpoints: (builder) => ({
		getSchedules: builder.query<any, string>({
			query: (userId: string) => ({
				url: `/schedule?userId=${userId}`,
			}),
			providesTags: ["Schedules"],
		}),
		getSingleRecurrenceData: builder.query<any, string>({
			query: (recurrenceId: string) => ({
				url: `/schedule/${recurrenceId}`,
				responseHandler: async (response: Response) => {
					const data = await response.json();
					return data;
				},
			}),
			providesTags: ["Schedules"],
		}),
		updateRecurrenceData: builder.mutation({
			query: ({ requestPayload, recurrenceId }) => ({
				url: `/schedule/${recurrenceId}`,
				method: "PUT",
				body: requestPayload,
				responseHandler: async (response) => {
					const data = await response.json();
					return data;
				},
			}),
			invalidatesTags: [{ type: "Schedules" }],
		}),
		deleteSingleRecurrenceById: builder.mutation({
			query: ({ requestPayload, recurrenceId }) => ({
				url: `/schedule/${recurrenceId}`,
				method: "DELETE",
				body: requestPayload,
				responseHandler: async (response) => {
					const data = await response.json();
					return data;
				},
			}),
			invalidatesTags: [{ type: "Schedules" }],
		}),
		createRecurrenceData: builder.mutation({
			query: (requestPayload) => ({
				url: `/schedule`,
				method: "POST",
				body: requestPayload,
				responseHandler: async (response) => {
					const data = await response.json();
					return data;
				},
			}),
			invalidatesTags: [{ type: "Schedules" }],
		}),
	}),
});

export const {
	useLazyGetSchedulesQuery,
	useLazyGetSingleRecurrenceDataQuery,
	useUpdateRecurrenceDataMutation,
	useCreateRecurrenceDataMutation,
	useDeleteSingleRecurrenceByIdMutation,
} = scheduleApi;
