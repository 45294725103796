import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { digitalScheduleApi } from "../../services/digitalScheduleApi";
import { downtimeApi } from "../../services/downtimeApi";
import { dtcApi } from "../../services/dtcApi";
import { faultCodesApi } from "../../services/faultCodesApi";
import { scheduleApi } from "../../services/scheduleApi";
import { truckApi } from "../../services/truckApi";
import { vehicleStatusApi } from "../../services/vehicleStatusApi";
import pageAlertsReducer from "../../stores/alerts/alertsPageSlice";
import appReducer from "../../stores/app/appSlice";
import pageDashboardReducer from "../../stores/dashboard/dashboardPageSlice";
import pageDowntimeReducer from "../../stores/downtime/downtimePageSlice";
import pageNotificationsReducer from "../../stores/notifications/notificationsPageSlice";
import pageRecurrenceReducer from "../../stores/recurrence/recurrencePageSlice";
import langReducer from "../lang/langSlice";
import loginReducer from "../login/loginSlice";
import tokenReducer from "../tokenHandling/tokenSlice";
import configReducer from "./configSlice";

export const store = configureStore({
	reducer: {
		config: configReducer,
		lang: langReducer,
		app: appReducer,
		pageAlerts: pageAlertsReducer,
		pageDowntime: pageDowntimeReducer,
		pageDashboard: pageDashboardReducer,
		pageNotifications: pageNotificationsReducer,
		pageRecurrence: pageRecurrenceReducer,
		login: loginReducer,
		tokenHandling: tokenReducer,
		[truckApi.reducerPath]: truckApi.reducer,
		[faultCodesApi.reducerPath]: faultCodesApi.reducer,
		[dtcApi.reducerPath]: dtcApi.reducer,
		[scheduleApi.reducerPath]: scheduleApi.reducer,
		[downtimeApi.reducerPath]: downtimeApi.reducer,
		[vehicleStatusApi.reducerPath]: vehicleStatusApi.reducer,
		[digitalScheduleApi.reducerPath]: digitalScheduleApi.reducer,
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			downtimeApi.middleware,
			truckApi.middleware,
			faultCodesApi.middleware,
			scheduleApi.middleware,
			dtcApi.middleware,
			vehicleStatusApi.middleware,
			digitalScheduleApi.middleware,
		),
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
