// app
export const paramsSearchBar = "search";
export const paramsTruckTags = "truckTags";
export const paramsTruckIds = "truckIds";
export const paramsTruckSelected = "truckSelected";
export const paramsDate = "date";

// dashboard
export const paramsSidesheetDashboard = "ssd";

// alerts
export const paramsTruckName = "truckName";
