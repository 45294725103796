import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useQueryParams = () => {
	const [params, setSearchParams] = useSearchParams();

	const getQueryParam = (key: string) => {
		return params.get(key);
	};

	const setQueryParam = (key: string, value: string) => {
		setSearchParams((prevParams) => {
			prevParams.set(key, value);
			return prevParams;
		});
	};

	const isQueryParamEqual = (key: string, value: string) => {
		return params.get(key) === value;
	};

	const getBooleanQueryParam = (key: string) => {
		const paramValue = params.get(key);
		return paramValue === "true";
	};

	const setBooleanQueryParam = (key: string, value: boolean) => {
		const stringValue = value ? "true" : "false";
		setSearchParams((prevParams) => {
			prevParams.set(key, stringValue);
			return prevParams;
		});
	};

	const deleteQueryParam = (key: string) => {
		setSearchParams((prevParams) => {
			prevParams.delete(key);
			return prevParams;
		});
	};

	useEffect(() => {
		// Cleanup function if needed
		return () => {
			// Perform any cleanup actions here
		};
	}, []); // Adjust dependencies as necessary

	return {
		getQueryParam,
		setQueryParam,
		isQueryParamEqual,
		getBooleanQueryParam,
		setBooleanQueryParam,
		deleteQueryParam,
	};
};

export default useQueryParams;
