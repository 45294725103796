import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configuration/setup/store";

interface NotificationsPageState {
	reloadPage: boolean;
	openDialogNotifications: boolean;
	tableNotificationsData: {
		isLoading: boolean;
		data: any;
	};
	singleNotificationData: any;
}

const initialState: NotificationsPageState = {
	tableNotificationsData: {
		isLoading: false,
		data: [],
	},
	singleNotificationData: {},
	reloadPage: false,
	openDialogNotifications: false,
};

const NotificationsPageSlice = createSlice({
	name: "notificationsPage",
	initialState,
	reducers: {
		triggerReloadPage: (state) => {
			state.reloadPage = !state.reloadPage;
		},
		setTableNotificationsData: (state, action: PayloadAction<any>) => {
			state.tableNotificationsData.data = action.payload;
		},
		setTableNotificationsDataLoading: (state, action: PayloadAction<boolean>) => {
			state.tableNotificationsData.isLoading = action.payload;
		},
		setSingleNotificationData: (state, action: PayloadAction<any>) => {
			state.singleNotificationData = action.payload;
		},
		openDialogNotifications: (state) => {
			state.openDialogNotifications = true;
		},
		closeDialogNotifications: (state) => {
			state.openDialogNotifications = false;
		},
	},
});

export const {
	setTableNotificationsDataLoading,
	setSingleNotificationData,
	triggerReloadPage,
	openDialogNotifications,
	closeDialogNotifications,
	setTableNotificationsData,
} = NotificationsPageSlice.actions;
export const getDialogState = (state: RootState) => state.pageNotifications.openDialogNotifications;
export const getNotificationsPageData = (state: RootState) => state.pageNotifications;
export default NotificationsPageSlice.reducer;
