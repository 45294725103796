import CustomState from "@rio-cloud/rio-uikit/CustomState";
import { useIntl } from "react-intl";

const WelcomePlaceholder = () => {
	const intl = useIntl();

	return (
		<>
			<CustomState
				outerClassName="position-absolute top-30pct shadow-default"
				headline={intl.formatMessage({ id: "dashboard.welcome.title" })}
				message={intl.formatMessage({ id: "dashboard.welcome.subtitle" })}
				icons={[
					{ name: "rioglyph rioglyph-truck-baseline", color: "text-color-highlight" },
					{ name: "rioglyph rioglyph-detail-view-info", color: "text-color-info" },
					{ name: "rioglyph rioglyph-cog", color: "text-color-status-driving" },
				]}
			/>
		</>
	);
};

export default WelcomePlaceholder;
