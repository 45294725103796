import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useIntl } from "react-intl";
import useCurrentPath from "../hooks/useCurrentPath";
import { DEFAULT_ROUTE, routes } from "./Router";

const isUnknownRoute = (routeNames: string[], pathname: string): boolean => !routes.some((route) => pathname + "/".startsWith(route));

const DefaultRedirect = () => {
	const intl = useIntl();
	document.title = "RIO " + intl.formatMessage({ id: "general.app.name" });
	const path = useCurrentPath();
	const navigate = useNavigate();

	useEffect(() => {
		if (isUnknownRoute(routes, path!)) {
			navigate(DEFAULT_ROUTE);
		}
	}, [path]);

	return null;
};

export default DefaultRedirect;
