import { NavLink, useSearchParams } from "react-router-dom";
import { paramsSearchBar, paramsSidesheetDashboard, paramsTruckName } from "../../constants/params";

export const LinkWithQuery = ({ children, to, ...props }: any) => {
	const [params] = useSearchParams();

	const newParams = new URLSearchParams(params.toString());
	newParams.delete(paramsSearchBar);
	newParams.delete(paramsSidesheetDashboard);
	newParams.delete(paramsTruckName);

	if (to === "/alerts" || to === "/downtime") {
	} else if (to === "/dashboard") {
		newParams.delete("date");
	} else {
		newParams.delete("date");
	}

	return (
		<NavLink to={{ pathname: to, search: newParams.toString() }} {...props}>
			{children}
		</NavLink>
	);
};

export default LinkWithQuery;
