import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../constants/services";

export const downtimeApi = createApi({
	reducerPath: "downtimeApi",
	baseQuery: dynamicBaseQuery,
	tagTypes: ["Downtime"],
	endpoints: (builder) => ({
		getDowntimeRecords: builder.mutation({
			query: (requestPayload) => ({
				url: `/downtime/simple`,
				method: "POST",
				body: requestPayload,
				responseHandler: async (response) => {
					const data = await response.json();

					const infoMap = data.info.reduce((acc: { [x: string]: any }, item: { vehicle: string | number }) => {
						acc[item.vehicle] = item;
						return acc;
					}, {});

					const updatedData = data.data.map((vehicle: { timeInFence: any; vehicle: string | number }) => {
						return {
							...vehicle,
							availability: infoMap[vehicle.vehicle].availability,
							info: infoMap[vehicle.vehicle] || {},
						};
					});

					return {
						...data,
						data: updatedData,
					};
				},
			}),
		}),
	}),
});

export const { useGetDowntimeRecordsMutation } = downtimeApi;
