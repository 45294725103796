import ListMenu from "@rio-cloud/rio-uikit/ListMenu";
import SplitDialog from "@rio-cloud/rio-uikit/SplitDialog";
import { useState } from "react";

const HowToUse = ({ show, setShow }: any) => {
	const [selectedKey, setSelectedKey] = useState<string | null>(null);

	const menuItems = [
		{
			group: "Páginas",
			navItems: [
				{
					key: "1",
					item: (
						<span className={selectedKey === "1" ? "active" : ""} onClick={() => setSelectedKey("1")}>
							Dashboard
						</span>
					),
				},
				{
					key: "2",
					item: (
						<span className={selectedKey === "2" ? "active" : ""} onClick={() => setSelectedKey("2")}>
							Alertas
						</span>
					),
				},
				{
					key: "3",
					item: (
						<span className={selectedKey === "3" ? "active" : ""} onClick={() => setSelectedKey("3")}>
							Downtime
						</span>
					),
				},
			],
		},
	];

	const handleClose = () => {
		setShow(false);
		setSelectedKey(null);
	};

	const renderContent = () => {
		switch (selectedKey) {
			case "1":
				return (
					<div>
						<div className="h4">Dashboard Content</div>
						<p>Details about the Dashboard.</p>
					</div>
				);
			case "2":
				return (
					<div>
						<div className="h4">Alertas Content</div>
						<p>Detalhes sobre Alertas</p>
					</div>
				);
			case "3":
				return (
					<div>
						<div className="h4">Downtime Content</div>
						<p>Detalhes sobre Downtime</p>
					</div>
				);
			default:
				return (
					<div>
						<div className="h4">Select an option</div>
						<p>Please select a menu item to view content.</p>
					</div>
				);
		}
	};

	return (
		<>
			<SplitDialog
				show={show}
				title="Como navegar pela aplicação"
				onClose={handleClose}
				leftContent={<ListMenu menuItems={menuItems} groupClassName="padding-left-0" />}
				footer={<div className="text-italic text-left">Aprenda como utilizar a aplicação - RIO Monitor de Manunteção</div>}
				rightContent={renderContent()}
			/>
		</>
	);
};

export default HowToUse;
